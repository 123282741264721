.proposal-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    .content-heading{
        font-size: 22px;
    }
    .get-in-touch {
        color: #419bad;
        padding: 10px;
        border: 1px solid #419bad;
        background: transparent;
        border-radius: 26px;
        transition: 0.3s;
        cursor: pointer;
        
        &:hover {
          color: white;
          padding: 10px;
          border: 1px solid #419bad;
          background: #419bad;
          border-radius: 26px;
        }
      }
}