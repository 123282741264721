.header
    margin-top: 15px
    margin-bottom: 10px
    text-align: center
    border-bottom: 3px solid #a0138e
    padding-bottom: 5px
    .start-now
        margin: 0px 15px
        padding: 9px 29px
        line-height: 40px
        border: #a0138e solid
        border-radius: 17px
