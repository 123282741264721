@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100&display=swap');

body, html {
    font-family: 'Prompt', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
.App{
    padding-bottom: 20px;
    .logo {
      margin-top: 100px;
        .image-logo {
          text-align: center;
          img {
            width: 188px;
            height: 55px;
            object-fit: contain;
            object-position: center center;
          }
        }
        .heading-logo {
          font-size: 22px;
          font-weight: 600;
          display: block;
          text-align: center;
        }
        .sub-heading-logo {
          font-size: 13px;
          display: block;
          text-align: center;
        }
      }
}

